import packageJson from "../../../../package.json";

// Values are replaces by webpack at build time;
declare const BRANCH: string;
declare const VERSION: string;
declare const SPA_SERVER: string;
declare const SPA_URL: string;

export const env = {
  logRequests: false,
  version: packageJson.version,
  server: SPA_SERVER,
  url: SPA_URL,
  build: VERSION,
  branch: BRANCH,
};
