import chalk from "chalk";
import { DateTime } from "luxon";
import { type UpdateResult } from "mongodb";

const MAX_NO_ERRORS = 500;
let lastErrors: string[] = [];

const addError = (level: string, message: string) => {
  lastErrors.push(
    `${DateTime.now().toFormat("HH:mm:ss:SSS")}: ${level} ${message}`
  );
  lastErrors = lastErrors.slice(-MAX_NO_ERRORS);
};

export const log = {
  getLastErrors: () => lastErrors,

  debug: (...text: unknown[]) => {
    console.log(chalk.gray(text));
  },

  info: (text: string) => {
    addError("INFO:", text);
    console.log(chalk.blue(text));
  },

  warning: (text: string) => {
    addError("WARNING:", text);
    console.log(chalk.yellow(text));
  },

  error: (text: string) => {
    addError("ERROR:", text);
    console.log(chalk.red(text));
  },

  dir: (...text: unknown[]) => {
    console.dir(text);
  },

  db: (collection: string, name: string, res: UpdateResult) => {
    console.log(
      chalk.gray(
        `Written ${name} to ${collection}: Responded ${res.acknowledged}. Modified: ${res.modifiedCount} Created: ${res.upsertedCount} Matched: ${res.matchedCount}`
      )
    );
  },
};
