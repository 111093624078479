import "common/fastRefreshFix";

import "normalize.css";
import "applications/spa/index.scss";

import { log } from "common/log";
import { env } from "common/env/spa/env";

/**
 * Fetch the main application async, after the css
 * has loaded. This results in a nicely styled loading page.
 */
const applicationStartUp = async () => {
  log.info("Fucking Factory Fun");
  log.info(`Version ${env.branch}:${env.build}:${env.version}`);

  log.info("Loading application...");

  const application = await import("applications/spa/application");
  await application.bootstrap();
};

applicationStartUp()
  .then(() => {
    log.info("Application up and running");
  })
  .catch((ex) => {
    log.error(`Application crashed: ${ex}`);
    log.dir(ex.stack);
  });
